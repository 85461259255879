/*
Galano Grotesque
*/
@font-face {
  font-family: 'GalanoGrotesque';
  font-weight: 700;
  font-display: swap;
  src: url('/app/fonts/GalanoGrotesque-Bold.woff2') format('woff2'),
    url('/app/fonts/GalanoGrotesque-Bold.woff') format('woff');
}

@font-face {
  font-family: 'GalanoGrotesque';
  font-weight: 500;
  font-display: swap;
  src: url('/app/fonts/GalanoGrotesque-Medium.woff2') format('woff2'),
    url('/app/fonts/GalanoGrotesque-Medium.woff') format('woff');
}

/*
Proxima Nova
*/

@font-face {
  font-family: 'ProximaNova';
  font-weight: 400;
  font-display: swap;
  src: url('/app/fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('/app/fonts/ProximaNova-Regular.woff') format('woff');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 600;
  font-display: swap;
  src: url('/app/fonts/ProximaNova-Semibold.woff2') format('woff2'),
    url('/app/fonts/ProximaNova-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 700;
  font-display: swap;
  src: url('/app/fonts/ProximaNova-Bold.woff2') format('woff2'),
    url('/app/fonts/ProximaNova-Bold.woff') format('woff');
}

/*
Rubik
*/

@font-face {
  font-family: 'Rubik';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url(/app/fonts/rubik/Rubik-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url(/app/fonts/rubik/Rubik-LightItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(/app/fonts/rubik/Rubik-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url(/app/fonts/rubik/Rubik-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url(/app/fonts/rubik/Rubik-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url(/app/fonts/rubik/Rubik-MediumItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(/app/fonts/rubik/Rubik-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url(/app/fonts/rubik/Rubik-BoldItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url(/app/fonts/rubik/Rubik-Black.woff2) format('woff2');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url(/app/fonts/rubik/Rubik-BlackItalic.woff2) format('woff2');
}