html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'GalanoGrotesque';
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#__next {
  height: 100%;
}

#launcher {
  display: none;
}
